.split-view {
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.description {
    margin-right: 2rem;
}

.resume {
    padding: 3rem;
    width: 60%;
}

@media screen and (max-width: 700px) {
    .split-view {
        display: block;
        /* flex-direction: column; */
    }

    .resume {
        /* padding: rem; */
        width: 90%;
    }

    .description {
        margin-right: 0rem;
        float: left;
    }
}

@media screen and (max-width: 500px) {
    .split-view {
        display: block;
        /* flex-direction: column; */
    }

    .resume {
        /* padding: rem; */
        width: 100%;
    }

    .description {
        margin-right: 0rem;
        float: left;
    }
}