.forceWhite {
  color: white !important;
}

.forceBlack {
  color: black !important;
}

.italic {
  font-style: italic;
}

/*
FAB = 0a9fe0
IO = 1c6dad
VA = 912d89
LE = ca2c80
NT = ef2a7a
*/

/*
fa to bio = background: linear-gradient(to bottom right, #0a9fe0, #1c6dad);
*/
.HomeGradient {
  background: linear-gradient(to right, #0a9fe0, #6251a2);
  /* background: linear-gradient(to bottom right,#4273be, #753f91); */
  /* background: linear-gradient(to bottom right, #4273be, #cc2c7f); */
  /*
  blues: 1c6bab 
  purple:415aa2
  */
}

.ResumeGradient {
  background: linear-gradient(to right, #4273be, #753f91);
  /* background: linear-gradient(to bottom right,#6a4696, #486fbb); */
  /*
  blues:  3486ce
  4b6bb7
  4273be
  */
}

.PortfolioGradient {
  background: linear-gradient(to right, #544898, #9b2d88);
  /* background: linear-gradient(to bottom right, #045597, #00aeef); */
  /* background: linear-gradient(to bottom right, #1c6bab, #9b2d88); */
}

.ContactGradient {
  background: linear-gradient(to right, #862d8b, #cc2c7f);
}

.App {
  font-size: 1.2em;
  font-weight: 100;
  color: white;
  letter-spacing: 0.05em;
}

.force-full-view {
  height: 90vh;
}

.center {
  text-align: center;
}

.mobile {
  padding: 0em;
}

.parragraph {
  display: flex;
  flex-direction: row;
}

li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  list-style: circle;
  margin-left: 1.5rem;
}

ul {
  margin: 1rem;
  font-style: italic;
}

.navbar {
  height: 10vh;
  padding-left: 3rem;
  padding-right: 3rem;
}

.navbar-burger {
  margin-top: 1rem;
  color: black;
  margin-right: 1rem;
}

.navbar-item {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.logo {
  padding-top: 0rem;
}

.underline {
  text-decoration: underline;
  padding-bottom: 0.3rem;
}

.largeFont {
  font-size: 1.5rem;
}

@media screen and (max-width: 450px) {
  .view {
    padding: 0em;
    padding-bottom: 10em;
  }

  .logo {
    padding-top: 0rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-bottom: 0rem;
  }

  ul {
    margin-left: 0rem;
  }
}

@media screen and (min-width: 1400px) {
  .view {
    padding: 5em;
    padding-bottom: 10em;
  }
}