.main {
    display: flex;
    flex-direction: row;
}
.subtitle {
    padding-top: 0.3rem;
}

.gitlablogo {
    enable-background:new 0 0 500 500;
}

.linkedinlogo {
    enable-background:new 0 0 18 18;
}

.logo-row {
    margin: 5rem 5rem 0rem 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.fv-social-icon {
    font-size: 5rem;
}
.fv-social-icon:hover {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .logo-row {
        margin: 5rem 0rem 0rem 0rem;
    }

    .fv-social-icon {
        font-size: 3rem;
    }
  } 

@media screen and (max-width: 500px) {
    .logo-row {
        margin: 5rem 0rem 0rem 0rem;
    }

    .fv-social-icon {
        font-size: 2.2rem;
    }
  } 