.project {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0rem 0rem 0rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid white;
}

.project:first-child {
    margin: 0rem;
    padding: 0rem;
}

.project:last-child {
    border-bottom: 0px solid white;
}

.project-desc {
    flex: 0.75;
    padding: 2rem;
}

.project-img {
    padding-top: 2rem;
    flex-direction: column;
}

.project-img-file:hover {
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .project {
        flex-direction: column;
    }
}

@media screen and (max-width: 500px) {
    .project {
        flex-direction: column;
    }

    .project-img {
        margin-left: -2rem;
    }

    .project-img-file {
        width: 90%;
    }
}